export default [
  {
    title: 'Inicio',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'ACL',
    /* children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
      {
        title: 'Test',
        route: 'test-card',
      },
    ], */
  },
  /* {
    title: 'Solicitudes',
    route: 'requests-list',
    icon: 'ListIcon',
  },
  {
    title: 'Usuarios',
    route: 'apps-users-list',
    icon: 'UsersIcon',
  }, */
]
