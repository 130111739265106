export default [
  {
    header: 'Generales',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Buscar Seriales',
    route: 'search-products',
    icon: 'SearchIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Numeros de Transporte',
    route: 'order-numbers',
    icon: 'HashIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Subir Seriales',
    route: 'upload-products',
    icon: 'UploadCloudIcon',
  },
  {
    title: 'Subir Sims',
    route: 'upload-sims',
    icon: 'UploadIcon',
  },
  {
    title: 'Pedidos',
    route: 'order-list',
    icon: 'CheckSquareIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Ver pedidos',
        route: 'order-list',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Crear pedido',
        route: 'create-order',
        action: 'read',
        resource: 'ACL',
      },
    ]
  },
  {
    title: 'POP',
    icon: 'CheckCircleIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Ver productos',
        route: 'pop-product-list',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Ver pedidos',
        route: 'pop-order-list',
        action: 'read',
        resource: 'ACL',
      },
    ]
  },
  {
    title: 'SIMs',
    icon: 'CastIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Ver pedidos SIM',
        route: 'sim-order-list',
        action: 'read',
        resource: 'ACL',
      },
    ]
  },
  {
    title: 'Novedades',
    route: 'product-newness',
    icon: 'AlertTriangleIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Pistoleo temprano',
    route: 'early-enlistment',
    icon: 'LoaderIcon',
  },
/*   {
    title: 'Alistamiento',
    route: 'enlistment-dispatch',
    icon: 'ArchiveIcon',
    action: 'read',
    resource: 'ACL',
  }, */
  {
    title: 'Inventario',
    route: 'inventory',
    icon: 'LayersIcon',
  },
  {
    header: 'Configuraciones',
  },
  {
    title: 'Configuración',
    icon: 'SettingsIcon',
    children: [
      /* {
        title: 'Usuarios',
        route: 'charts-echart',
      }, */
      {
        title: 'Destinos',
        route: 'config-destinations-list',
      },
      {
        title: 'Kits',
        route: 'config-kits-list',
      },
    ],
  },
  /* {
    title: 'Formularios',
    route: 'maps-leaflet',
    icon: 'ClipboardIcon',
  }, */
]